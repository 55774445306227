import React from "react";

import { Grid, Typography } from '@mui/material'

import { FaAndroid, FaTabletAlt } from "react-icons/fa";

import AppCard from './AppCard'

import { listVersions } from "../data"

import TranslateManager from "../translations/translateManager"

export default function Home(props) {
  const list = Object.values(listVersions);

  let items = list.map((item, idx) => {
    if(item.hideOnHome != null && item.hideOnHome === true || item.enable == false) {
      return null;
    }
   
    if (item.list != null && item.list[0] != null && !item.isbeta) {
      return (
        <Grid item lg={3} md={6} xs={12} key={"card-" + idx}>
            <AppCard appData={item} standalone={true} />
        </Grid>
      );
    } else {
      return null;
    }
  })

  // const langSelector = TranslateManager.getLangSelector();

  return (
    <div>

      {/* {langSelector} */}

      <div style={{marginBottom : "2em"}}>
        <Typography style={{margin: "1em 0 .5em 0", fontFamily:"Source Serif Pro semi-bold", fontSize: "3em"}} align="center" variant="string" component="div">{TranslateManager.getText("TITLE")}</Typography>
        <Typography style={{fontSize: "1.5em"}} align="center" variant="string" component="div">{TranslateManager.getText("SUBTITLE")}</Typography>
        <Typography style={{fontSize: "1.5em"}} align="center" variant="string" component="div">{TranslateManager.getText("APP_WORKING_INFO")}</Typography>
      </div>
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        {items}
      </Grid>
      
    </div>
  );
}

